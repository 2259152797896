import "./index.less";
import animate from 'ant-design-vue/es/_util/css-animation';
import moment from "moment";
import {isCpf, isEmpty, unobservable} from "@/resources/util";
import timezone from "@/resources/timezone.js";
import http from "@/http";
import ModalTerms from "@/components/modal-terms/modalTerms";

moment.locale('pt-br');

export default {

    beforeCreate() {

        this.$http.get('/clientes.usuarios.obterConvite', { params: { codigo: this.$route.params.codigo } }).then(({ data }) => {

            if (data) {

                this.data.id = data.id;
                this.data.user.id = data.usuario.id;
                this.data.user.email = data.usuario.email;
                this.data.user.name = data.usuario.name;
                this.data.user.cpf = data.usuario.cpf;
                this.data.user.username = data.usuario.username;

                const captcha = document.createElement('script');
                captcha.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
                document.body.appendChild(captcha);

                captcha.onload = () => {

                    grecaptcha.ready(() => {

                        this.recaptcha.widgetId = grecaptcha.render("g-recaptcha", {

                            'sitekey': '6LfnLrUZAAAAAFg5Ulfl_MTPrRNjepSdwGpim3dY',
                            'callback': this.recaptchaVerifyCallback,
                            'expired-callback': this.recaptchaExpiredCallback
                        });
                    });
                };
            }

            this.convite = data;

        }).finally(() => {

            this.$nextTick(() => {

                this.loading = false;
            })
        }).catch(err => console.log(err));

    },
    created() {

        this.timezones = unobservable(timezone.map(item => ({ label: item, key: item })));
    },
    data() {

        return {

            loading: false,
            termosModal: false,
            aceite: false,
            convite: undefined,
            recaptcha: {

                widgetId: null,
                response: null
            },
            data: {

                id: null,
                user: {
                    
                    username: undefined,
                    password: undefined,
                    name: undefined,
                    cpf: '',
                    gender: undefined,
                    email: undefined,
                    phone: undefined,
                    birth_date: null,
                    avatar: undefined,
                    timezone: null,
                    confirmation: undefined
                }
            },
            rules: {

                username: [

                    {
                        required: true,
                        message: 'Por favor, informe nome de usuário!'
                    },
                    {
                        min: 4,
                        max: 20,
                        message: 'Por favor, informe pelo menos 4 e no máximo 20 caracteres.'
                    },
                    {
                        pattern: /^[a-z0-9_.]*$/,
                        message: 'Utilize apenas letras minúsculas, números e/ou os caracteres ._'
                    },
                    {
                        pattern: /[a-z]/i,
                        message: 'Por favor, informe pelo menos uma letra.'
                    },
                    {
                        asyncValidator: (rule, value, cb) => {

                            if (value === this.convite.usuario.username) {

                                return cb();

                            } else if (/[._]{2,}/.test(value)) {

                                cb('Não pode haver pontos ou underlines ._ consecutivos.');

                            } else if (value.charAt(0) === '.' || value.charAt(value.length - 1) === '.') {

                                cb('O usuário deve começar e terminar apenas com uma letra, número ou underline.');

                            } else {

                                return http.get(`/usuarios.checarUsuario?usuario=${value}`).then(({ data }) => {

                                    if (data) cb('Usuário já existente, por favor escolha outro.');
                                    cb();

                                }).catch(e => cb());
                            }
                        }
                    }
                ],
                password: [

                    {
                        required: true,
                        message: `Por favor, informe uma senha!`,
                    },
                    {
                        validator: (rule, value, cb) => {

                            if (!value) value = '';

                            let tests = {

                                characters: {

                                    is: value.length >= 6 && value.length <= 20,
                                    message: 'no mínimo 6 e no máximo 20 caracteres',
                                    force: 15
                                },
                                lower: {

                                    is: /[a-z]/.test(value),
                                    message: 'no mínimo um caracter minúsculo',
                                    force: 10
                                },
                                upper: {

                                    is: /[A-Z]/.test(value),
                                    message: 'no mínimo um caracter maiúsculo',
                                    force: 25
                                },
                                number: {

                                    is: /\d/.test(value),
                                    message: 'um número',
                                    force: 25
                                },
                                special: {

                                    is: /\W/.test(value),
                                    message: 'um caracter especial (Ex: @#$,?&%!.)',
                                    force: 25
                                }
                            };

                            let msg = `A senha deve conter `;
                            let parts = [];

                            let force = 0;

                            for (let k in tests) {

                                let test = tests[k];

                                if (test.is) {

                                    force += test.force;

                                } else {

                                    parts.push(test.message);
                                }
                            }

                            if (parts.length) {

                                if (parts.length > 1) parts[parts.length - 1] = `e ${parts[parts.length - 1]}`;
                                msg += parts.join(', ');
                            }

                            this.passwordForce = force;
                            this.passwordStatus = force <= 30 ? '#f5222d' : (force <= 60 ? 'rgb(255, 85, 0)' : (force < 100 ? '#1890ff' : 'rgb(135, 208, 104)'));

                            cb(parts.length ? msg : undefined)
                        }
                    }
                ],
                confirmation: {

                    required: true,
                    validator: (rule, value, cb) => {

                        if (!value) return cb('A confirmação da senha é obrigatória');
                        cb(this.data.user.password !== value ? 'As senhas não coincidem' : undefined);
                    }
                },
                cpf: {

                    required: true,
                    validator: (rule, value, cb) => {

                        cb(!isEmpty(value) ? (isCpf(value) ? undefined : `Informe um CPF válido.`) : `Por favor, informe seu CPF!`)
                    }
                },
                name: {

                    required: true,
                    message: 'Por favor, informe seu nome.'
                }
            }
        }
    },
    methods: {

        recaptchaVerifyCallback(response) {

            this.recaptcha.response = response;
            this.throwInvite();
        },
        recaptchaExpiredCallback() {

            this.recaptcha.response = null;
            grecaptcha.reset(this.recaptcha.widgetId);
        },
        disabledDataNascimento(current) {

            return current._d && current._d > moment().endOf('day');
        },
        dummyRequest({ file, onSuccess }) {

            setTimeout(() => {

                onSuccess("ok");

            }, 0);
        },
        handleChange(info) {

            let loadPicture;

            if (info.file.status === "uploading") {

                const reader = new FileReader();

                loadPicture = new Promise((resolve, reject) => {

                    reader.onload = function () {

                        info.file.type.slice(0, 5) !== "image" ? reject() : resolve(this.result);
                    }

                }).then((data) => {

                    this.data.user.avatar = null;

                    this.$nextTick(() => {

                        this.data.user.avatar = data;
                    })

                }).catch(() => {

                    this.$message.error(`É permitido apenas imagens no formato: ".png, .jpeg, .jpg, .bmp, .jfif"`, 10);
                });

                reader.readAsDataURL(info.file.originFileObj);
            }
        },
        handleRemovePhoto(e) {

            e.stopPropagation();

            this.$confirm({

                title: 'Deseja realmente remover a logo da empresa?',
                okText: 'Sim',
                okType: 'danger',
                cancelText: 'Não',
                onOk: () => {

                    this.data.user.avatar = null;
                }
            });
        },
        filterTimezones(input, option) {

            return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_').toLowerCase().trim()) >= 0)
        },
        getTransitionProps() {

            const transitionName = 'fade';

            return {

                on: {

                    enter: (el, done) => {

                        animate(el, `${transitionName}-enter`, done);
                    },
                    leave: (el, done) => {

                        animate(el, `${transitionName}-leave`, done);
                    }
                }
            };
        },
        throwInvite() {

            this.$refs.form.validate(valid => {

                if (!valid) return;

                if (!this.recaptcha.response) {

                    grecaptcha.execute(this.recaptcha.widgetId);
                    return;
                }

                this.loading = !this.loading;

                let usuario = Object.assign({}, this.data);

                if (usuario.user.birth_date) usuario.user.birth_date = moment(usuario.user.birth_date).format('YYYY-MM-DD HH:mm:ss');
                delete usuario.user.cidade;
                delete usuario.user.confirmation;
                usuario.captcha = this.recaptcha.response;

                this.$http.post('/clientes.usuarios.salvar', { data: usuario }).then(({ data }) => {

                    if (data) {

                        this.$success({

                            title: 'Atenção',
                            content: 'Cadastro realizado com sucesso',
                            okText: 'Ir para o login',
                            onOk: () => {

                                this.$router.replace({ name: 'acesso', hash: '#login' });
                            }
                        });
                    }
                }).catch(err => {

                    console.log(err);
                    this.recaptchaExpiredCallback();
                    this.$error({

                        title: `Desculpe, houve um erro!`,
                        content: `${err.message}`
                    })
                }).finally(() => this.loading = false);
            })
        },
        handleSubmit(e) {

            e.preventDefault();
            if(!this.loading) this.throwInvite();
        },
        handleTerm(val) {

            this.aceite = val;
            this.termosModal = false;
        }
    },
    render() {

        const rowOptions = { props: { gutter: 16 } };

        const formProps = {

            ref: "form",
            attrs: {

                id: 'convite-form'
            },
            props: {

                model: this.data.user,
                rules: this.rules
            },
            on: {

                submit: this.handleSubmit
            }
        };

        const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.data.user.timezone = currentTz || 'America/Sao_Paulo';

        return (
            <a-spin spinning={this.loading}>
                <div id="doc-main">
                    <header>
                        <a href="https://zonasete.com.br" target="_blank">
                            <img src={require('@/assets/logo.svg')} alt="Zona Sete Vistorias" class="logo" />
                        </a>
                        <div class="title">
                            <h1>Zona Sete Vistorias</h1>
                            <h3>Cadastro por convite</h3>
                        </div>
                    </header>
                    <a-divider />
                    <div class="content ant-form">
                        {this.convite ? this.convite.expired
                            ?
                            <a-alert
                                v-show={!this.loading}
                                message="Convite expirado"
                                type="error"
                                showIcon
                            >
                                <template slot="description">
                                    <b>Esse convite expirou em {moment(this.convite.expires_at).format('HH:mm DD/MM/YYYY')}</b><br />
                                    Para prosseguir com seu cadastro solicite um novo convite a <b>{this.convite && this.convite.organizacao.nome_fantasia}</b>
                                </template>
                            </a-alert>
                            :
                            <div v-show={!this.loading}>
                                <a-alert
                                    message="Atenção"
                                    type="info"
                                    showIcon
                                >
                                    <template slot="description">
                                        Esta é a página de cadastro de usuário do sistema <b>Zona Sete Vistorias</b>! <br />
                                        Você está se cadastrando por convite da empresa <b>{this.convite && this.convite.organizacao.nome_fantasia}</b>.
                                        <p>Crie sua senha e após utilize em conjunto com seu nome de usuário, e-mail ou CPF para acessar o sistema.</p>
                                        {this.convite.description && <p><b>Observação do remetente:</b> {this.convite.description}</p>}
                                    </template>
                                </a-alert>
                                <a-form-model {...formProps}>
                                    <a-row {...rowOptions}>
                                        <a-col {...{ props: { md: 12 } }}>
                                            <a-form-model-item

                                                label="Avatar"
                                            >
                                                <div style="align-items: center;justify-content: center; flex-direction: column;display: flex;">
                                                    <div id="photo">
                                                        <transition name="slide-fade">
                                                            {this.data.user.avatar ?
                                                                <img class="stylePhoto change-photo-transition"
                                                                    src={this.data.user.avatar} /> :
                                                                <g-icon class="change-photo-transition"
                                                                    id="empty-icon"
                                                                    icon={['fas', 'user']} />
                                                            }
                                                        </transition>
                                                        <div id="photo-actions">
                                                            <a-upload
                                                                accept=".png, .jpeg, .jpg, .bmp, .jfif"
                                                                beforeUpload={this.beforeUpload}
                                                                customRequest={this.dummyRequest}
                                                                onChange={this.handleChange}
                                                                showUploadList={false}
                                                            >
                                                                <a-tooltip
                                                                    placement="top"
                                                                    title={this.data.user.avatar ? 'Trocar' : 'Adicionar'}
                                                                >
                                                                    <span>
                                                                        <g-icon icon={['fas', this.data.user.avatar ? 'edit' : 'plus']} />
                                                                    </span>
                                                                </a-tooltip>
                                                                <a-tooltip
                                                                    placement="top"
                                                                    title="Excluir"
                                                                    v-show={this.data.user.avatar}
                                                                >
                                                                    <span onClick={this.handleRemovePhoto}>
                                                                        <g-icon icon={['fas', 'trash']} />
                                                                    </span>
                                                                </a-tooltip>
                                                            </a-upload>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a-form-model-item>
                                        </a-col>
                                        <a-col {...{ props: { md: 12 } }}>
                                            <a-form-model-item
                                                label="Email"
                                                prop="email"
                                            >
                                                <a-input
                                                    disabled
                                                    v-model={this.data.user.email}
                                                />
                                            </a-form-model-item>
                                            <a-form-model-item
                                                label="Nome"
                                                prop="nome"
                                            >
                                                <a-input
                                                    allowClear
                                                    placeholder="Informe seu nome"
                                                    v-model={this.data.user.name}
                                                />
                                            </a-form-model-item>
                                        </a-col>
                                    </a-row>
                                    <a-row {...rowOptions}>
                                        <a-col {...{ props: { md: 12 } }}>
                                            <a-form-model-item
                                                prop="username"
                                            >
                                                <template slot="label">
                                                    <span>Usuário <a-tooltip title="Utilize apenas letras minúsculas, números e os caracteres . _ -" placement="right"><g-icon icon={['far', 'question-circle']} /></a-tooltip></span>
                                                </template>
                                                <a-input
                                                    allowClear
                                                    placeholder="Informe um nome de usuário"
                                                    maxLength={20}
                                                    v-model={this.data.user.username}
                                                />
                                            </a-form-model-item>
                                        </a-col>
                                        <a-col {...{ props: { xs: 24, md: 12 } }}>
                                            <a-form-model-item
                                                prop="timezone"
                                            >
                                                <span slot="label">
                                                    Timezone&nbsp;
                                                    <a-tooltip title={`Este é o timezone de sua região (${currentTz})! Caso não seja o relacionado à sua região, verifique as configurações do computador, pois pode acarretar diferença nos horários registrados em diferentes fusos horários. Em caso de dúvida, entre em contato com o suporte.`}>
                                                        <g-icon icon={['far', 'question-circle']} />
                                                    </a-tooltip>
                                                </span>
                                                <a-select
                                                    disabled
                                                    allowClear
                                                    showSearch
                                                    placeholder="Selecione"
                                                    filterOption={this.filterTimezones}
                                                    options={this.timezones}
                                                    v-model={this.data.user.timezone}
                                                />
                                            </a-form-model-item>
                                        </a-col>
                                    </a-row>
                                    <a-row {...rowOptions}>
                                        <a-col {...{ props: { md: 12 } }}>
                                            <transition {...this.getTransitionProps()}>
                                                <div class="change-password">
                                                    <a-form-model-item
                                                        prop="password"
                                                    >
                                                        <template slot="label">
                                                            <span>Senha <a-tooltip title="Dicas para uma senha forte: substitua, por exemplo, um a por @, um e por 3, um i por !, misturando os caracteres e impedindo que sua senha seja facilmente descoberta" placement="right"><g-icon icon={['far', 'question-circle']} /></a-tooltip></span>
                                                        </template>
                                                        <a-input-password
                                                            allowClear
                                                            placeholder="Informe uma senha"
                                                            autocomplete="off"
                                                            maxLength={20}
                                                            v-model={this.data.user.password}
                                                        />
                                                        <a-progress
                                                            percent={this.passwordForce}
                                                            strokeColor={this.passwordStatus}
                                                            style="height: 2px;"
                                                            v-show={this.data.user.password}
                                                        />
                                                    </a-form-model-item>
                                                </div>
                                            </transition>
                                        </a-col>
                                        <a-col {...{ props: { md: 12 } }}>
                                            <a-form-model-item
                                                label="Confirme a senha"
                                                prop="confirmation"
                                            >
                                                <a-input-password
                                                    allowClear
                                                    placeholder="Confirme a senha"
                                                    disabled={!this.data.user.password}
                                                    autocomplete="off"
                                                    maxLength={20}
                                                    v-model={this.data.user.confirmation}
                                                />
                                            </a-form-model-item>
                                        </a-col>
                                    </a-row>
                                    <a-row {...rowOptions}>
                                        <a-col {...{ props: { md: 12 } }}>
                                            <a-form-model-item
                                                label="CPF"
                                                prop="cpf"
                                            >
                                                <a-input
                                                    disabled
                                                    allowClear
                                                    placeholder="Informe seu CPF"
                                                    v-mask={"###.###.###-##"}
                                                    v-model={this.data.user.cpf}
                                                />
                                            </a-form-model-item>
                                        </a-col>
                                        <a-col {...{ props: { md: 12 } }}>
                                            <a-form-model-item
                                                label="Sexo"
                                                prop="gender"
                                            >
                                                <a-select
                                                    placeholder="Selecione seu sexo"
                                                    options={[{ value: 'M', label: 'Masculino' }, { value: 'F', label: 'Feminino' }]}
                                                    v-model={this.data.user.gender}
                                                />
                                            </a-form-model-item>
                                        </a-col>
                                    </a-row>
                                    <a-row {...rowOptions}>
                                        <a-col {...{ props: { md: 12 } }}>
                                            <a-form-model-item
                                                label="Telefone"
                                                prop="phone"
                                            >
                                                <a-input
                                                    allowClear
                                                    placeholder="Informe seu telefone"
                                                    v-mask={["(##) ####-####", "(##) #####-####"]}
                                                    v-model={this.data.user.phone}
                                                />
                                            </a-form-model-item>
                                        </a-col>
                                        <a-col {...{ props: { md: 12 } }}>
                                            <a-form-model-item
                                                label="Data de nascimento"
                                                prop="birth_date"
                                            >
                                                <a-date-picker
                                                    allowClear
                                                    placeholder="Selecione a data de nascimento"
                                                    format="DD/MM/YYYY"
                                                    showTime={false}
                                                    showToday={false}
                                                    disabledDate={this.disabledDataNascimento}
                                                    v-ant-picker-mask
                                                    v-model={this.data.user.birth_date}
                                                />
                                            </a-form-model-item>
                                        </a-col>
                                    </a-row>
                                </a-form-model>
                                <a-button
                                    type="link"
                                    class="button"
                                    onClick={() => this.termosModal = true}
                                >
                                    <g-icon icon={['far', this.aceite ? 'check-square' : 'square']} />
                                    Eu li e concordo com os termos de serviço
                                </a-button>
                                <a-button
                                    class="button"
                                    html-type="submit"
                                    form="convite-form"
                                    type="primary"
                                    loading={this.loading}
                                    disabled={!this.aceite}
                                >
                                    Salvar
                                </a-button>
                                <div id="g-recaptcha"
                                     data-size="invisible">
                                </div>
                                <ModalTerms
                                    visible={this.termosModal}
                                    onAcceptTerm={(val) => this.handleTerm(val)}
                                />
                            </div>
                            : <a-alert
                                v-show={!this.loading}
                                message="Convite inválido"
                                type="error"
                                showIcon
                            >
                                <template slot="description">
                                    <b>O convite informado é inválido ou inexistente!</b><br />
                                    Para se cadastrar como usuário solicite um novo convite.
                                </template>
                            </a-alert>
                        }
                    </div>
                    <footer class="rodape">
                        &copy; {(new Date()).getFullYear()} Todos os direitos reservados<br />
                        <a href="https://gxz.com.br" target="_blank"><img src={require('@/assets/logo-gxz-light.png')} alt="GXZ" /></a>
                    </footer>
                </div>
            </a-spin>
        );
    }
}
